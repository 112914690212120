import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
	AccountInformationDto,
	DefaultOrgData,
	Maintenance,
	OrganizationsResponse,
	OrgData
} from './organizations-store.model';
@Injectable({
	providedIn: 'root'
})
export class OrganizationsStoreService {
	private selectedOrganization = new BehaviorSubject({ isOrgSelected: false, orgName: '', orgId: '', orgNumber: '' });
	public organizationList!: OrganizationsResponse[];
	private defaultOrganization = new BehaviorSubject({ orgId: '', orgName: '' });
	public appVersion = new BehaviorSubject('');
	public maintenanceModeInfo = new BehaviorSubject<Maintenance | null>(null);
	public maintenanceModeInfoFe = new BehaviorSubject<Maintenance | null>(null);
	private accountId = '';
	public readonly accountInformation = signal<AccountInformationDto>({});

	constructor() {
		// If page got refreshed, get the selected organization detail from session
		const selectedOrganization = navigator.cookieEnabled ? sessionStorage.getItem('selectedOrganization') : '';
		if (selectedOrganization) {
			const selectedOrgData = JSON.parse(selectedOrganization);
			this.selectedOrganization.next(selectedOrgData);
		}
	}
	/**
	 * gets the selected organization data as observable
	 * @returns Observable that provides selected organization updates
	 */
	public getSelectedOrganization$(): Observable<OrgData> {
		return this.selectedOrganization.asObservable();
	}
	/**
	 * Sets selected organization data
	 * @param isOrgSelected - boolean representation of organization selection status
	 * @param orgName - string representation of organization name
	 * @param orgId - string representation of organization id
	 */
	public setSelectedOrganization(isOrgSelected: boolean, orgName: string, orgId: string, orgNumber = ''): void {
		const selectedOrgData = {
			isOrgSelected: isOrgSelected,
			orgName: orgName,
			orgId: orgId,
			orgNumber: orgNumber
		};
		sessionStorage.setItem('selectedOrganization', JSON.stringify(selectedOrgData));
		this.selectedOrganization.next(selectedOrgData);
	}
	/**
	 * stores the OrganizationList
	 * @param organization - OrganizationsResponse representation of org data,
	 * if params is undefined or null, then an empty array will be used as the default value.
	 */
	public storeOrganizationList(organization: OrganizationsResponse[] = []): void {
		this.organizationList = [...organization];
	}

	/**
	 * delete the organization by id
	 * @param organizationId - string representation of the Organization Id
	 */
	public deleteOrganization(organizationId: string): void {
		const index = this.organizationList.findIndex((organization) => organization.id === organizationId);
		if (index !== -1) {
			this.organizationList.splice(index, 1);
		}
	}
	/**
	 * update the organization row
	 * @param organization - OrganizationsResponse representation of org data
	 */
	public updateOrganization(organization: OrganizationsResponse): void {
		const index = this.organizationList.findIndex((organizationRow) => organizationRow.id === organization.id);
		if (index !== -1) {
			this.organizationList[index] = { ...organization };
		}
	}
	/**
	 *	gets the stored OrganizationList
	 * @returns - OrganizationsResponse[] - list of organizations
	 */
	public getOrganizationList(): OrganizationsResponse[] {
		return this.organizationList;
	}

	/**
	 * Set default organization
	 * @param orgId - string representation of selected organization id
	 * @param org - string representation of selected organization name
	 */
	public setDefaultOrganization(orgId: string, orgName: string): void {
		const defaultOrgDetails = {
			orgId: orgId,
			orgName: orgName
		};
		this.defaultOrganization.next(defaultOrgDetails);
	}

	/**
	 *	gets the stored Default Organization
	 * @returns - DefaultOrgData - default organization details
	 */
	public getDefaultOrganization(): Observable<DefaultOrgData> {
		return this.defaultOrganization.asObservable();
	}

	public setVersion(version: string): void {
		this.appVersion.next(version);
	}

	public getVersion(): Observable<string> {
		return this.appVersion.asObservable();
	}

	/**
	 * set maintenance mode information
	 * @param maintenance - Maintenance representation of the received maintenance mode information
	 */
	public setMaintenance(maintenance: Maintenance): void {
		this.maintenanceModeInfo.next(maintenance);
	}

	/**
	 * gets the stored maintenance mode information
	 */
	public getMaintenance(): Observable<Maintenance | null> {
		return this.maintenanceModeInfo.asObservable();
	}

	/**
	 * set FE maintenance mode information
	 * @param maintenance - Maintenance representation of the received FE maintenance mode information
	 */
	public setFeMaintenance(maintenance: Maintenance): void {
		this.maintenanceModeInfoFe.next(maintenance);
	}

	/**
	 * gets the stored FE maintenance mode information
	 */
	public getFeMaintenance(): Observable<Maintenance | null> {
		return this.maintenanceModeInfoFe.asObservable();
	}
	/**
	 * Set accountId
	 * @param accountId - string representation of selected accountId based on Organization id
	 */
	public setAccountId(accountId: string): void {
		this.accountId = accountId;
	}

	/**
	 * gets the stored accountId
	 * @returns - accountId
	 */
	public getAccountId(): string {
		return this.accountId;
	}

	/**
	 * Sets the complete account information into the state
	 * @param accountInfo - AccountInformationDto representation of api response from session/account-info api
	 */

	public setAccountInfo(accountInfo: AccountInformationDto): void {
		this.accountInformation.set(accountInfo);
	}

	/**
	 * Updates the account information in the state
	 * @param accountInfo - partial representation of AccountInformationDto to update in state
	 */
	public updateAccountInfo(accountInfo: Partial<AccountInformationDto>): void {
		this.accountInformation.set({ ...this.accountInformation(), ...accountInfo });
	}
}
